export const getFormattedDate = (inp_date) => {
    const isoDateString = inp_date;
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  } 

  export const formatDateString = (dateString) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
  
    // Convert to IST by adding 5.5 hours (in milliseconds)
    const IST_OFFSET = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(date.getTime() + IST_OFFSET);
    
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, '0');
    const day = String(istDate.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };
  