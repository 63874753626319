// src/components/ServiceCard.js
import React from 'react';

export const ServiceCard = ({ service, title, data }) => {
  if (!service) return <p>No Service History available</p>;

  const labelMap = {
    dateTime: 'Date & Time',
    purpose: 'Purpose Of Visit',
    parts: 'Parts Replaced',
    ipTds: 'I/P TDS',
    opTds: 'O/P TDS',
    techSign: 'Technician Signature',

  };

  
  const serviceArray = service.service;
  const columns = Object.keys(labelMap);

  function getLabel(category, value) {
    // Check if the category is valid
    if (!data[category]) {
      return value;
    }
    const labels = data[category].filter(item => {
      if (Array.isArray(value)) {
        // If value is an array, check if any of the array's values match item.value
        return value.some(val => {
          if (Array.isArray(item.value)) {
            return item.value.includes(val);
          } else {
            return item.value === val;
          }
        });
      } else {
        // Handle case where value is a single value
        if (Array.isArray(item.value)) {
          return item.value.includes(value);
        } else {
          return item.value === value;
        }
      }
    }).map(item => item.label);
  
    return labels.length > 0 ? labels : ['Label not found'];
  
  }

  return (
    
    <div className="user-card">
      <div className="user-card__header">
        <div className="user-card__title">{title}</div>
      <div className="user-card__content">
      <div className="table-container">
      <table className="user-table">
      <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}  style={{textAlign: 'center', color: 'black', padding: '5px' }}>{labelMap[column]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {serviceArray.map((item, rowIndex) => (
            <tr key={item._id || rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} style={{textAlign: 'center' }}>
                  {Array.isArray(getLabel(column, item[column]))
                    ? getLabel(column, item[column]).join(', ')
                    : getLabel(column, item[column]) || 'N/A'}  
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </div>
    </div>
    </div>



  );
};

