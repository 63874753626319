import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../api/auth';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


    const handleSubmit = async (e) => {
     e.preventDefault();
        setLoading(true);

        if (!username || !password) {
          setError('Please fill in all fields.');
          setLoading(false);
          return;
        }

        try {
          const { token, user } = await login(username, password);
          // Store token (e.g., in localStorage)
          localStorage.setItem('authToken', token);
          localStorage.setItem('name', user.name);
          localStorage.setItem('phone', user.phone);
          localStorage.setItem('isAdmin', JSON.stringify(user.isAdmin) == 'true' ? true : false); // Ensure it's stored as a JSON boolean
          localStorage.setItem('needsPasswordChange', user.needsPasswordChange ? 'true' : 'false');
          // Redirect or update UI
          // e.g., window.location.href = '/dashboard';
          dispatch({ type: 'LOGIN_SUCCESS',  payload: {
            name: user.name,
            phone: user.phone,
            isAdmin: JSON.stringify(user.isAdmin) == 'true' ? true :  false
          }
        });
        if (user.needsPasswordChange) {
          navigate('/change-password');
        } else if (user.isAdmin) {
          navigate('/admin');
        } else {
          navigate('/dashboard');
        }
        } catch (error) {
          setError('Invalid username or password');
        } finally {
          setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  

  return (


   <div>
      <div id="login">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Login</h2>
                <p>
                  Please fill the below details to login
                </p>
              </div>
              <form name="login" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="text"
                        name="text"
                        value={username}
                        className="form-control"
                        placeholder="Phone number"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        value={password}
                        className="form-control"
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i
                        className={`fa fa-${showPassword ? 'eye-slash' : 'eye'}`}
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>
                  </div>
                </div>
                <p className="help-block text-danger">{error}</p>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg" disabled={loading}>
                  {loading ? 'Logging in...' : 'Login'}
                </button>
              </form>
            </div>
          </div>
    
        </div>
      </div>
    </div>
  );
};

