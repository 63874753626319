import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { addCustomer, updateCustomer } from '../api/customerData';
import { addPart, updatePart } from '../api/parts';  // Adjust the path as needed
import { addMachine, getMachines, updateMachine } from '../api/machine'; // Adjust the path as needed
import { formatDateString } from '../api/utils';
// import flatpickr from 'flatpickr'; // Import flatpickr
// import 'flatpickr/dist/themes/material_blue.css'; // Optional, for a better UI theme

import ReactDatePicker from 'react-datepicker'; // React date picker
import moment from 'moment'; // Moment.js
import "react-datepicker/dist/react-datepicker.css"; // DatePicker styles

export const Modal = ({ isOpen, onClose, type, subCategory, initialData, parts, cities }) => {
  const [formData, setFormData] = useState(initialData || {});
  const [action, setAction] = useState('Add');
  const [machineOptions, setMachineOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState(subCategory || []);
  const [machineData, setMachineData] = useState([]);
  const [selectedMachineType, setSelectedMachineType] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [oldPhoneNumber, setOldPhoneNumber] = useState(''); // Store old phone number
  const [filteredParts, setFilteredParts] = useState([]); // State for filtered parts
  useEffect(() => {
    if (isOpen) {
      const fetchMachines = async () => {
        try {
          const response = await getMachines();
          setMachineData(response);

          const typeOptions = response.map(machine => ({
            value: machine.machineType,
            label: machine.machineType
          }));
          setMachineOptions(typeOptions);
        } catch (error) {
          console.error('Failed to fetch machines:', error);
        }
      };

      fetchMachines();
      initializeFormData();
    } else {
      resetForm();
    }
  }, [isOpen, type]);

  useEffect(() => {
    if (initialData) {
      setAction('Edit');
      setOldPhoneNumber(initialData.phone); // Store the old phone number
      const machineType  = initialData.machine_type;
      const partPrice = initialData.partPrice ? parseFloat(initialData.partPrice.replace(/[^0-9.]/g, '')) || 0 : ''; // Convert to number
      const machinePrice = initialData.machinePrice ? parseFloat(initialData.machinePrice.replace(/[^0-9.]/g, '')) || 0: ''; // Convert to number
      if (machineType) {
      const lastIndex = machineType.lastIndexOf('-');
      const mtype = machineType.substring(0, lastIndex).trim();
      const subcategory = machineType.substring(lastIndex + 1).trim();
      setFormData({
        ...initialData,
        machineType: mtype,
        machineSubCategory: subcategory, // Ensure this matches your structure
        partPrice,
        machinePrice,
        start_date: moment(initialData.start_date).format('DD-MM-YYYY'),
        end_date: moment(initialData.end_date).format('DD-MM-YYYY')
      });
      setSelectedMachineType(mtype);
    } else {
      setFormData({
        ...initialData,
        partPrice,
        machinePrice,
        start_date: moment(initialData.start_date).format('DD-MM-YYYY'),
        // Convert to dd-mm-yyyy
        end_date: moment(initialData.end_date).format('DD-MM-YYYY'),
      });
    }

    } else {
      setFormData({});
    }
  }, [initialData]);

  // useEffect(() => {
  //   // Initialize flatpickr on the input with the specified id
  //   flatpickr("#startDateInput", {
  //     dateFormat: "d-m-Y", // Date format (dd-mm-yyyy)
  //     onChange: (selectedDates) => {
  //       // Update the state when a date is selected from the calendar
  //       setFormData(prevData => {
  //         const startDate = selectedDates[0];
  //         const endDate = new Date(startDate);

  //         // Set end date as one year after the start date and one day before the start date
  //         endDate.setFullYear(startDate.getFullYear() + 1);
  //         endDate.setDate(startDate.getDate() - 1);

  //         // Format dates before setting state (dd-mm-yyyy format)
  //         const formattedStartDate = flatpickr.formatDate(startDate, "d-m-Y");
  //         const formattedEndDate = flatpickr.formatDate(endDate, "d-m-Y");

  //         return { ...prevData, start_date: formattedStartDate, end_date: formattedEndDate };
  //       });
  //     }
  //   });
  // }, []);
  
  useEffect(() => {
    if (selectedMachineType) {
      const selectedMachine = machineData.find(machine => machine.machineType === selectedMachineType);
      const subCategories = selectedMachine ? selectedMachine.subCategories : [];
      setSubCategoryOptions(subCategories.map(subCat => ({
        value: subCat,
        label: subCat
      })));

      const machineParts = parts[0][selectedMachineType]; // Access parts for the selected machine type
      if (machineParts) {
        const filtered = machineParts.map(part => ({
          value: part.value,
          label: part.label
        }));
        setFilteredParts(filtered);
      }
      // Set the selected subcategory if it exists in the options
      if (formData.machineSubCategory) {
        setFormData(prevData => ({ ...prevData, machineSubCategory: formData.machineSubCategory }));
      } else {
        setFormData(prevData => ({ ...prevData, machineSubCategory: '' })); // Reset if not found
      }
    } else {
      setSubCategoryOptions([]);
      setFilteredParts([]);
    }
  }, [selectedMachineType, machineData]);  

  
  const initializeFormData = () => {
    switch (type.toLowerCase()) {
      case 'customer':
        setFormData({
          name: '',
          phone: '',
          address: '',
          city: '',
          pin:'',
          email: '',
          start_date: '',
          end_date: '',
          machineType: '',
          machineSubCategory: ''
        });
        break;
      case 'part':
        setFormData({
          partName: '',
          machineType: '',
          machineSubCategory: '',
          partDescription: '',
          partCost: ''
        });
        break;
      case 'machine':
        setFormData({
          machineType: '',
          machineSubCategory: '',
          machineName: '',
          machineDescription: '',
          machinePrice: ''
        });
        break;
      default:
        setFormData({});
        break;
    }
  };


  const resetForm = () => {
    setFormData({});
    setSelectedMachineType(null);
    setSubCategoryOptions([]);
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);
  
  const handleDropDownChange = (field) => (option) => {
    setFormData(prevData => {
      const newFormData = { ...prevData, [field]: option ? option.value : '' };
      
      if (field === 'machineType') {
        setSelectedMachineType(option ? option.value : '');
        newFormData['machineSubCategory'] = '';
      }
      return newFormData;
    });
  };

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData, [name]: value };
  
      // if (name === 'start_date' && value) {
      //   // Parse the start date using Moment.js
      //   const startDate = moment(value, 'DD-MM-YYYY');
  
      //   // Calculate the end date: one year after the start date and one day before
      //   const endDate = startDate.clone().add(1, 'years').subtract(1, 'days');
  
      //   // Format both start and end dates to 'DD-MM-YYYY' format
      //   const formattedStartDate = startDate.format('DD-MM-YYYY');
      //   const formattedEndDate = endDate.format('DD-MM-YYYY');
  
      //   // Update formData with formatted dates
      //   updatedData.start_date = formattedStartDate;
      //   updatedData.end_date = formattedEndDate;
      // }
  
      return updatedData;
    });
  };

  const handleDateChange = (date) => {
    // If date is not null, we handle the logic
    if (date) {
      setFormData(prevData => {
        const updatedData = { ...prevData };
  
        // Parse the start date using Moment.js
        const startDate = moment(date).format('DD-MM-YYYY');
  
        // Calculate the end date: one year after the start date and one day before
        const endDate = moment(startDate, 'DD-MM-YYYY')
          .add(1, 'years')
          .subtract(1, 'days')
          .format('DD-MM-YYYY');
  
        // Update formData with formatted dates
        updatedData.start_date = startDate;
        updatedData.end_date = endDate;
  
        return updatedData;
      });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSuccessMessage('');
      setErrorMessage('');
      if (action.toLowerCase() === 'edit') {
        switch (type.toLowerCase()) {
          case 'customer':
            await updateCustomer({ ...formData, oldPhone: oldPhoneNumber }); 
            break;
          case 'part':
            await updatePart(formData); // Update part data
            break;
          case 'machine':
            await updateMachine(formData); // Update machine data
            break;
          default:
            throw new Error('Unknown type');
        }
      } else { // For 'add' action
        switch (type.toLowerCase()) {
          case 'customer':
            await addCustomer(formData); // Add new customer
            break;
          case 'part':
            await addPart(formData); // Add new part
            break;
          case 'machine':
            await addMachine(formData); // Add new machine
            break;
          default:
            throw new Error('Unknown type');
        }
      }

      setSuccessMessage(`Successfully ${action.toLowerCase()}ed ${type.charAt(0).toUpperCase() + type.slice(1)}!`);
      setFormData({});
      setSelectedMachineType(null);
      setSubCategoryOptions([]);
      setTimeout(onClose, 2000);
    } catch (error) {
      console.error('Error adding data:', error);
      setErrorMessage(error.response ? error.response.data.message : 'Failed to add machine');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>{`${action} ${type.charAt(0).toUpperCase() + type.slice(1)} Details`}</h2>
        <p>Please add all the required fields below</p>
        <form onSubmit={handleSubmit} validate className="serviceComplete">
          {type.toLowerCase() === 'customer' && (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Customer Name"
                      value={formData.name || ''}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Phone Number</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Customer Phone Number"
                    value={formData.phone || ''}
                    onChange={handleChange}
                    required
                  />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Email (Optional)</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Customer Email"
                    value={formData.email || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Address</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Customer Address"
                    value={formData.address || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>City</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={cities}
                      value={cities.find(option => option.value === formData.city) || null}
                      onChange={handleDropDownChange('city')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Pin Code</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="number"
                    name="pin"
                    className="form-control"
                    placeholder="Pin Code"
                    value={formData.pin || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Type</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={machineOptions}
                      value={machineOptions.find(option => option.value === formData.machineType) || null}
                      onChange={handleDropDownChange('machineType')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine SubCategory</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={subCategoryOptions}
                      value={subCategoryOptions.find(option => option.value === formData.machineSubCategory) || null}
                      onChange={handleDropDownChange('machineSubCategory')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Contract Start Date</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  {/* <input
                    id="startDateInput"
                    type="text"
                    name="start_date"
                    className="form-control"
                    value={formData.start_date ? formData.start_date : ''}  // Ensure it's in dd-mm-yyyy format
                    onChange={handleChange}
                    placeholder="dd-mm-yyyy"
                    maxLength="10"  // Limit input length to fit the format
                    style={{ backgroundColor: 'transparent' }} // Ensure no background color
                  /> */}
                  <ReactDatePicker
                  className="form-control"
                  selected={formData.start_date ? moment(formData.start_date, 'DD-MM-YYYY').toDate() : null} // Convert formatted date back to Date object
                  onChange={handleDateChange} // Handle date selection
                  dateFormat="dd-MM-yyyy" // Date format displayed in the picker
                  placeholderText="dd-mm-yyyy" // Placeholder for the input
                />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Contract End Date</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                    <ReactDatePicker
                      className="form-control"
                      selected={formData.end_date ? moment(formData.end_date, 'DD-MM-YYYY').toDate() : null} // Convert formatted date back to Date object
                      dateFormat="dd-MM-yyyy" // Date format displayed in the picker
                      placeholderText="dd-mm-yyyy" // Placeholder for the input
                      readOnly
                    />
                </div>
              </div>
              </div>
            </>
          )}

          {type.toLowerCase() === 'part' && (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Type</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                    options={machineOptions}
                    value={machineOptions.find(option => option.value === formData.machineType)}
                    onChange={handleDropDownChange('machineType')}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine SubCategory</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={subCategoryOptions}
                      value={subCategoryOptions.find(option => option.value === formData.machineSubCategory)}
                      onChange={handleDropDownChange('machineSubCategory')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Part Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                <div className="form-group">
                  <Select
                    options={filteredParts} // Use the filtered parts here
                    value={filteredParts.find(option => option.value === formData.partName)}
                    onChange={handleDropDownChange('partName')}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Part Description</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="partDescription"
                    className="form-control"
                    placeholder="Part Description"
                    value={formData.partDescription || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Part Price</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="number"
                    name="partCost"
                    className="form-control"
                    placeholder="Part Price"
                    value={formData.partCost || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
            </>
          )}

          {type.toLowerCase() === 'machine' && (
            <>
               <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Type</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="machineType"
                    className="form-control"
                    placeholder="Machine Type"
                    value={formData.machineType || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="machineName"
                    className="form-control"
                    placeholder="Machine Name"
                    value={formData.machineName || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Sub Category</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <Select
                    options={subCategory} // Ensure this is the correct source of options
                    value={subCategory.find(option => option.value === formData.machineSubCategory)}
                    onChange={handleDropDownChange('machineSubCategory')}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Description</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="machineDescription"
                    className="form-control"
                    placeholder="Machine Description"
                    value={formData.machineDescription || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Price</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="number"
                    name="machinePrice"
                    className="form-control"
                    placeholder="Machine Price"
                    value={formData.machinePrice || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
            </>
          )}

          <button type="submit" className="btn btn-custom btn-lg">
            {action} {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

        </form>
      </div>
    </div>
  );
};
