import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navigation from "./components/navigation";
import { Login } from "./components/login";
import { Home } from "./components/home";
import { VisitComplete } from "./components/visitComplete";
import { Dashboard } from "./components/dashboard";
import { ChangePassword } from "./components/changePassword";
import { Admin } from "./components/Admin";
import SmoothScroll from "smooth-scroll";
import { useSelector } from "react-redux";
import JsonData from './data/data.json';
import { useNavigate } from "react-router-dom";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [landingPageData, setLandingPageData] = useState(JsonData);
  const navigate = useNavigate();

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;

    // Decode the token to get its expiry time
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) return false;

    try {
      const payload = JSON.parse(atob(tokenParts[1]));
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      return Date.now() < expirationTime;
    } catch (error) {
      return false;
    }
  };


 return (
      <div id="app">
        <Navigation />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/admin" element={isAuthenticated && isAdmin ? <Admin  data={{ machineSubCategory: landingPageData.MachineSubCategory, parts: landingPageData.Parts, cities: landingPageData.Cities }} /> : <Navigate to="/login" />} />
          <Route path="/dashboard" element={isAuthenticated? <Dashboard data={landingPageData.Options} /> : <Navigate to="/login" />} />
          <Route path="/logout" element={!isAuthenticated ? <Navigate to="/login"/> : <Dashboard/>} />
          <Route path="/visit_complete" element={!isAuthenticated ? <Navigate to="/login"/> : <VisitComplete data={landingPageData.Options} />} />
        </Routes>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 1996 Zeel Aqua Solutions
            </p>
          </div>
        </div>
      </div>
  );
};

export default App;
